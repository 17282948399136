export const URLS = {
    SUBSCRIPTIONS: '/integrations/endpoint-lifecycle',
    V6_VWAN: '/integrations/virtual-wan',
    OLD_SUBSCRIPTIONS: '/app-subscriptions',
    CLIENT_SYNC_AAD: '/integrations/aad',
    CLIENT_SYNC_AD: '/integrations/ad',
    NETWORK: '/app-networks',
    OLD_NETWORK: '/networks',
    GATEWAYS: '/endpoints/gateways',
    OLD_GATEWAYS: '/gateways',
    OLD_OLD_GATEWAYS: '/app-gateways',
    CLIENTS: '/endpoints/clients',
    OLD_CLIENTS: '/clients',
    OLD_OLD_CLIENTS: '/app-clients',
    SERVICES: '/connections/services',
    OLD_SERVICES: '/services',
    OLD_OLD_SERVICES: '/app-services',
    APPWANS: '/connections/appwans',
    OLD_APPWANS: '/appwans',
    OLD_OLD_APPWANS: '/app-appwans',
    SETTINGS: '/organizations/settings',
    OLD_SETTINGS: '/settings',
    OLD_OLD_SETTINGS: '/app-settings',
    GROUPS: '/endpoints/groups',
    OLD_GROUPS: '/groups',
    OLD_OLD_GROUPS: '/app-groups',
    HOME: '/main',
    OLD_HOME: '/app-main',
    LOGIN: '/login',
    OLD_LOGIN: '/app-login',
    LOGS: '/logs',
    OLD_LOGS: '/app-logs',
    CLOUD_ZITI_USERS: '/user-identities/users',
    CLOUD_ZITI_USERS_ROLES: '/user-identities/user-roles',
    CLOUD_ZITI_INVITATIONS: '/user-identities/invitations',
    USER: '/user-identities/user',
    USERS: '/user-identities/users',
    USER_ROLES: '/user-identities/user-roles',
    OLD_USERS: '/users',
    OLD_OLD_USERS: '/app-users',
    RESOURCE_USAGE: '/reporting/resource-usage',
    OLD_RESOURCE_USAGE: '/reporting',
    DAILYSTATS: '/reporting/dailystats',
    OLD_DAILYSTATS: '/dailystats',
    MANAGE_INVITATIONS: '/user-identities/invitations',
    OLD_MANAGE_INVITATIONS: '/manage-invitations',
    PROFILE: '/profile',
    OLD_PROFILE: '/app-profile',
    PAGENOTFOUND: '/page-not-found',
    COLLECTORS: '/collectors',
    PROCESS_EXECUTIONS: '/process-executions',
    ATTRIBUTES_EXPLORER: '/attributes-explorer',
    NOTIFICATIONS: '/notifications',
    PASSWORD: '/app-password',
    BRANDING: '/branding',
    OLD_BRANDING: '/app-branding',
    USER_SETTINGS: '/user-settings',
    MANAGEMENT_EVENTS: '/management-events',
    FABRIC_LATENCY: '/fabric-latency',
    NETWORK_EVENTS: '/network-events',
    OLD_EVENTS: '/app-events',
    V7EVENTS: '/v7/events',
    ALARMS: '/alarms',
    OLD_ALARMS: '/app-alarms',
    VIRTUALWANSITES: '/endpoints/azure-virtual-wan-sites',
    OLD_VIRTUALWANSITES: '/azure-virtual-wan-sites',
    VIRTUALWANS: '/virtualwans',
    OLD_VIRTUALWANS: '/app-virtualwans',
    ERRORS: '/error-history',
    BILLING: '/billing',
    BILLING_DASHBOARD: '/billing/dashboard',
    ORG_BILLING: '/organization/billing',
    SUPPORT_BILLING: '/support/billing',
    CLOUD_ZITI_TOKENS: '/user-identities/tokens',
    TOKENS: '/user-identities/tokens',
    OLD_TOKENS: '/tokens',
    ORGANIZATIONS: '/organizations/manage',
    OIDC_AUDIENCES: '/oidc-audiences',
    OIDC_ISSUERS: '/oidc-issuers',
    OLD_ORGANIZATIONS: '/organizations',
    NETWORK_GROUPS: '/network-groups',
    NETWORK_GROUP_SUMMARY: '/reporting/network-group-summary',
    OLD_NETWORK_GROUP_SUMMARY: '/network-group-summary',
    DAILY_ENDPOINT_UTILIZATION: '/reporting/daily-endpoint-utilization',
    OLD_DAILY_ENDPOINT_UTILIZATION: '/daily-endpoint-utilization',
    CERTIFICATE_AUTHORITIES: '/certificate-authorities',
    ZITIAPPS: '/v7/connections/appwans',
    OLD_ZITIAPPS: '/v7/appwans',
    ZITIENDPOINTS: '/v7/endpoints',
    ZITIROUTERS: '/v7/routers',
    ZITIPOLICIES: '/v7/policies',
    ZITI_SERVICE_ER_POLICIES: '/v7/service-edge-router-policies',
    ZITISERVICES: '/v7/connections/services',
    ZITICONFIGS: '/v7/connections/configs',
    ZITICONFIGTYPES: '/v7/connections/config-types',
    ROLES: '/roles',
    PAYMENT_PROFILE: '/payment-profile',
    ACCOUNT_DETAILS: '/account-details',
    BILLING_SUBSCRIPTIONS: '/billing-subscriptions',
    BILLING_SUBSCRIPTION_OVERAGES: '/billing-subscription-overages',
    BILLING_SUBSCRIPTION_USAGE: '/billing-subscription-usage',
    BILLING_PAYMENT_PROFILE: '/billing/payment-profile',
    BILLING_ACCOUNT_DETAILS: '/billing/account-details',
    APP_BILLING_SUBSCRIPTIONS: '/billing/billing-subscriptions',
    APP_BILLING_SUBSCRIPTION_OVERAGES: '/billing/billing-subscription-overages',
    APP_BILLING_SUBSCRIPTION_USAGE: '/billing/billing-subscription-usage',
    ORG_PAYMENT_PROFILE: '/organization/payment-profile',
    ORG_ACCOUNT_DETAILS: '/organization/account-details',
    ORG_BILLING_SUBSCRIPTIONS: '/organization/billing-subscriptions',
    ORG_BILLING_SUBSCRIPTION_OVERAGES: '/organization/billing-subscription-overages',
    ORG_BILLING_SUBSCRIPTION_USAGE: '/organization/billing-subscription-usage',
    SUPPORT_PAYMENT_PROFILE: '/support/payment-profile',
    SUPPORT_ACCOUNT_DETAILS: '/support/account-details',
    SUPPORT_BILLING_SUBSCRIPTIONS: '/support/billing-subscriptions',
    SUPPORT_BILLING_SUBSCRIPTION_OVERAGES: '/support/billing-subscription-overages',
    SUPPORT_BILLING_SUBSCRIPTION_USAGE: '/support/billing-subscription-usage',
    GETTING_STARTED: '/getting-started',
    SAMPLE_SERVICE: '/getting-started/sample-service',
    OLD_ZITISERVICES: '/v7/services',
    V7DASHBOARD: '/v7/dashboard',
    NETWORKS6: '/networks6',
    NETWORKS_COMBINED: '/networks-combined',
    NETWORK_CONTROLLERS: '/network-controllers',
    ALLOCATED_IPS: '/allocated-ips',
    STORED_SECRETS: '/stored-secrets',
    ZITI_CERTIFICATE_AUTHORITIES: '/v7/certificate-authorities',
    IDENTITIES_BASE: '/user-identities',
    POSTURE_CHECKS: '/posture-checks',
    TOPOLOGY: '/v7/topology',
    METRICS: '/v7/metrics',
    // routes that can be accessed without authorization
    INVITATION: '/invitation',
    OLD_INVITATION: '/app-invitation',
    SIGNUP: '/trial',
    SELFSERVICE: '/selfservice',
    SIGNUP_COMPLETE: '/signup-complete',
    SIGNUP_RETURN: '/signup-return',
    AUTH_POLICIES: '/auth-policies',
    JWT_SIGNERS: '/jwt-signers',
    BROWZER: '/v7/browzer',
    BROWZER_APPS: '/browzer-apps',
    BROWZER_BOOTSTRAPPERS: '/browzer-bootstrappers',
    BROWZER_ROUTERS: '/browzer-routers',
    BROWZER_CERT_AUTHORITIES: '/browzer-cert-auth',
};

export const INFRASTRUCTURE_URLS = {
    DASHBOARD: '/network/dashboard',
    ROUTERS: '/infrastructure/routers',
    MANAGED_ROUTERS: '/network/edge-routers',
    METRICS: '/infrastructure/metrics',
    NETWORKS: '/infrastructure/networks',
    NETWORK_GROUPS: '/infrastructure/network-groups',
}

export const ZITI_URLS = {
    ZITI_DASHBOARD: '/dashboard',
    ZITI_IDENTITIES: '/identities',
    ZITI_JWT_SIGNERS: '/jwt-signers',
    ZITI_SERVICES: '/services',
    ZITI_ROUTERS: '/routers',
    ZITI_TRANSIT_ROUTERS: '/transit-routers',
    ZITI_CONFIGS: '/configs',
    ZITI_CONFIG_TYPES: '/config-types',
    ZITI_RECIPES: '/recipes',
    ZITI_TERMINATORS: '/terminators',
    ZITI_AUTH_POLICIES: '/auth-policies',
    ZITI_SERVICE_POLICIES: '/service-policies',
    ZITI_ROUTER_POLICIES: '/router-policies',
    ZITI_SERVICE_ROUTER_POLICIES: '/service-router-policies',
    ZITI_POSTURE_CHECKS: '/posture-checks',
    ZITI_CERT_AUTHORITIES: '/certificate-authorities',
    ZITI_SESSIONS: '/sessions',
    ZITI_API_SESSIONS: '/api-sessions',
    ZITI_LOGIN: '/login',
    ZITI_ORGANIZATION: '/organization',
    ZITI_PROFILE: '/profile',
    ZITI_SERVERS: '/servers',
    ZITI_SETTINGS: '/settings',
};
