import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Network, Notification, OidcAudience } from '@netfoundry-ui/shared/model';
import { NotificationService } from '@netfoundry-ui/shared/notification';
import { NetworkService, OidcAudienceService, URLS } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';

import _ from 'lodash';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';

@Component({
    selector: 'app-oidc-audience-form',
    templateUrl: './oidc-audience-form.component.html',
    styleUrls: ['./oidc-audience-form.component.scss'],
})
export class OidcAudienceFormComponent implements OnInit {
    Oidc_Audiences = URLS.OIDC_AUDIENCES;

    errors = {};
    processing = false;
    canEdit = true;
    networkId: string;
    emails = '';
    networkGroupName;
    isComplete = false;
    isBuilding;
    hideHelp = false;
    initialModel: OidcAudience = new OidcAudience();

    public model: OidcAudience = new OidcAudience();

    private subscription = new Subscription();

    constructor(
        public oidcAudienceService: OidcAudienceService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<OidcAudienceFormComponent>,
        public networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private router: Router,
        public growlerService: GrowlerService,
        public authorizationService: AuthorizationService
    ) {
  
    }

    ngOnInit() {

        this.initModel();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    hide(response?) {
        this.dialogRef.close(response);
    }




   async save() {
        if (await this.validate()) {

        if (this.model.id) {
            this.processing = true;
            this.subscription.add(
                this.oidcAudienceService.update(this.model.id, this.model).subscribe(
                    (data: OidcAudience) => {
                        this.model = data;
                        this.dialogRef.close();
                    },
                    (HttpErrorResponse) => {
                        this.processing = false;
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Error creating oidc audience.',
                                HttpErrorResponse.error.errors[0]
                            )
                        );
                    }
                )
            );
        } else {
            this.processing = true;
            this.subscription.add(
                this.oidcAudienceService.create(this.model).subscribe(
                    (data: OidcAudience) => {
                        this.model = data;
                        this.processing = false;
                        this.isComplete = true;
                    },
                    (HttpErrorResponse) => {
                        this.processing = false;
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Error creating oidc audience.',
                                HttpErrorResponse.error.errors[0]
                            )
                        );
                    }
                )
            );
        }
        }
    }

    paste(event: ClipboardEvent) {
        event.preventDefault(); 
        const data = event.clipboardData.getData('Text').trim();
      
        this.model.restrictedToOrganizationIds.push(...data.split(' '));
        this.model.restrictedToOrganizationIds = this.model.restrictedToOrganizationIds.filter(chip => !chip.includes(' '))
    }
    onKeyDown(event: KeyboardEvent) {
        if (event.key === ' ') {
            event.preventDefault();
            const element = event.target as HTMLElement;
            element.blur();
            element.focus();
        }
    }

    toggleActive() {
  
        this.model.active = !this.model.active;
    }


async validate() {
    this.errors = {};
    if(!this.model.id) {
        const errors = await this.oidcAudienceService.validateCreate(this.model);
        if(!_.isArray(errors)) {
            return false;
        }
        if (_.find(errors, (e) => e.includes('$.oidcIssuerId'))) {
            this.errors['oidcIssuerId'] = this.extractMessage(_.find(errors, (e) => e.includes('.oidcIssuerId')));
        }
        if (_.find(errors, (e) => e.includes('.audience'))) {
            this.errors['audience'] = this.extractMessage(_.find(errors, (e) => e.includes('.audience')));
        }
        if (_.find(errors, (e) => e.includes('.restrictedToOrganizationIds'))) {
            this.errors['restrictedToOrganizationIds'] = this.extractMessage(_.find(errors, (e) => e.includes('.restrictedToOrganizationIds')));
        }
        return errors.length === 0;
    } else {
        const errors = this.oidcAudienceService.update(this.model.id, this.model);
        if(!_.isArray(errors)) {
            return false;
        }
        if (_.find(errors, (e) => e.includes('$.oidcIssuerId'))) {
            this.errors['oidcIssuerId'] = this.extractMessage(_.find(errors, (e) => e.includes('.oidcIssuerId')));
        }
        if (_.find(errors, (e) => e.includes('.audience'))) {
            this.errors['audience'] = this.extractMessage(_.find(errors, (e) => e.includes('.audience')));
        }
        if (_.find(errors, (e) => e.includes('.restrictedToOrganizationIds'))) {
            this.errors['restrictedToOrganizationIds'] = this.extractMessage(_.find(errors, (e) => e.includes('.restrictedToOrganizationIds')));
        }
        return errors.length === 0;

        }
    }

    private extractMessage(error: string): string {
        const parts = error.split(':', 2); // Split at the first colon
        return parts.length > 1 ? parts[1].trim() : error; // Return message after colon
    }

    private initModel() {
        if (this.data.model) {
            this.initialModel = _.cloneDeep(this.data.model);
            this.model = this.data.model;
        }
        }
}
