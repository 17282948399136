import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CdkScrollable, ScrollDispatcher} from '@angular/cdk/scrolling';
import {
    ApiService,
    BrandingModeService,
    FeatureService,
    LoggerService,
    MenuService,
    NetworkService,
} from '@netfoundry-ui/shared/services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Environment, ENVIRONMENT, Network, NetworkV2, Tenant } from '@netfoundry-ui/shared/model';
import { take } from 'rxjs/operators';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { NetworkUpgradeService } from '@netfoundry-ui/feature-network-upgrade';
import { NavigationEnd, Router } from '@angular/router';
import { URLS } from '@netfoundry-ui/shared/services';
import { AccountService, BillingEnabledService } from '@netfoundry-ui/feature/shared-services';
import { Subscription } from 'rxjs';
import { invoke, debounce, defer, delay } from 'lodash';
import { SideNavigatorService } from './side-navigator.service';

@Component({
    selector: 'app-side-navigator',
    templateUrl: './side-navigator.component.html',
    styleUrls: ['./side-navigator.component.scss'],
})
export class SideNavigatorComponent implements OnInit, OnDestroy {

    @Input() disableNavigation = false;
    @Input() showExpandCollapseIcon = false;
    @Output() showExpandCollapseIconChange: EventEmitter<boolean> = new EventEmitter();

    dialogRef: any;
    isOpened = false;
    filterString = '';
    hasAdd = true;
    url = '';
    billingStatusInvalid = false;
    latencyUrl = URLS.FABRIC_LATENCY;
    browZerURL = URLS.BROWZER;
    cloudzitiBillingURL = URLS.ORG_PAYMENT_PROFILE
    networkControllerURL = URLS.NETWORK_CONTROLLERS;
    disabledToolTip = 'Network must be provisioned to access this page';
    Organizations = URLS.ORGANIZATIONS;
    subscription = new Subscription();
    enterpriseBilling = false;
    hideBilling = false;
    hideMenuNavBar = true && this.featureService.isCloudZiti;
    currentNetwork: any = {};
    currentZitiEdgeDetails: any = {};
    appNameMap = {
      infrastructure: 'Network',
      networks: 'Network',
      billing: 'Billing',
      organization: 'Organization',
      browzer: 'BrowZer',
      support: 'Support'
    };
    appName = '';
    majorVersionNumber = '';
    minorVersionNumber = '';
    patchVersionNumber = '';
    onMenuScrollDebounced = debounce(this.onMenuScroll.bind(this), 100, {leading: true, maxWait: 100});
    hideMenuScrollbarDebounced = debounce(this.hideMenuScrollbar.bind(this), 1000);
    constructor(
        public svc: SideNavigatorService,
        private logger: LoggerService,
        private apiService: ApiService,
        public authorizationService: AuthorizationService,
        @Inject(ENVIRONMENT) private environment: Environment,
        private networkUpgradeService: NetworkUpgradeService,
        public menuService: MenuService,
        public featureService: FeatureService,
        public brandingService: BrandingModeService,
        public brandingModeService: BrandingModeService,
        public billingEnabledService: BillingEnabledService,
        public accountService: AccountService,
        private router: Router,
        private scrollDispatcher: ScrollDispatcher,
        private cd: ChangeDetectorRef
    ) {
      this.scrollDispatcher.scrolled().subscribe((event: any) => {
        this.onMenuScroll(event);
      });
    }

    onMenuScroll(event: any) {
      this.hideMenuNavBar = false;
      defer(() => {
        this.cd.detectChanges();
      });
      this.hideMenuScrollbarDebounced();
    }

    hideMenuScrollbar() {
      this.hideMenuNavBar = true && this.featureService.isCloudZiti;
      defer(() => {
        this.cd.detectChanges();
      });
    }

    get hideNav() {
        return !this.featureService.isCloudZiti && this.featureService.disableNewNavigation;
    }

    get showOrgPicker() {
        return (
            this.svc.area === 'Organization' &&
            (!this.billingEnabledService.billingEnabled || this.svc.tenants.length > 1) &&
            this.authorizationService.canListTenants() &&
            (!this.svc.isSelfService || this.authorizationService.isSupportUser)
        );
    }

    get hideTitle() {
        return (this.svc.area === 'Organization' && this.showOrgPicker) || !this.svc.currentNav.title;
    }

    get showAppNameTitle() {
      return this.environment.v3Enabled;
    }

    hideMenuItem(item: any) {
      let hide = false;
      const nameItemVal = item.route || item.action;
      switch (nameItemVal) {
        case 'openTour':
        case URLS.GETTING_STARTED:
          hide = !this.authorizationService.canCreateNetworks();
          break;
        case URLS.STORED_SECRETS:
          hide = !this.authorizationService.canListStoredSecret();
          break;
          case URLS.OIDC_AUDIENCES:
          hide = !this.featureService.experimentalFeatures;
          break;
        case URLS.BILLING:
        case URLS.PAYMENT_PROFILE:
          if (this.hideBilling) {
            hide = true;
          } else if (this.appName === 'support') {
            hide = this.authorizationService.canListUserIdentities();
          }
          break;
        default:
          hide = false;
          break;
      }
      return hide;
    }

    ngOnInit(): void {
        this.subscription.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.url = event.url.split('?')[0];
                }
            })
        );
        this.subscription.add(
            this.accountService.currentAccount.subscribe((account) => {
                this.enterpriseBilling = account?.billingMode === 'Enterprise';
            })
        );
      this.subscription.add(
        this.apiService.currentContract.subscribe((contract) => {
          this.hideBilling = contract?.productId === 'NetfoundyTrialPlan';
        })
      );
        this.subscription.add(
          this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network;
            if (this.currentNetwork?.id) {
              this.majorVersionNumber = this.apiService.getNetworkVersion(this.currentNetwork);
              this.minorVersionNumber = this.apiService.getNetworkMinorVersion(this.currentNetwork);
              this.patchVersionNumber = this.apiService.getNetworkPatchVersion(this.currentNetwork);
            }
            this.hasAdd = this.authorizationService.canCreateNetworks();
          })
        );
        this.subscription.add(
          this.menuService.appChanged.subscribe((appName) => {
            this.appName = appName;
          })
        );
        this.subscription.add(
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.svc.updateSelectedNavItem();
            }
          })
        );
        this.subscription.add(
          this.apiService.currentZitiEdgeDetails.subscribe((details) => {
            this.currentZitiEdgeDetails = details;
          })
        );
    }

    navItemSelected(item: any) {
      return item.selectedRoutes?.includes(this.svc.selectedNavItem.route)
    }

    doNavAction(item: any) {
        if (this.disableNavigation || this.svc.isNavItemDisabled(item)) {
          return;
        }
        if (item.route) {
            this.svc.navigateToRoute(item);
        } else if (item.link) {
            window.open(item.link, '_blank');
        } else if (item.action) {
            invoke(this.svc, item.action);
        }
    }

    toggleList(event: any) {
        event.stopPropagation();
        this.isOpened = !this.isOpened;
    }

    stopProp(event: any) {}

    public setNetwork(network: any) {
        this.apiService.setCurrentNetwork(network);
        this.closeList();
    }

    public setTenant(tenant: any) {
        this.apiService.setCurrentTenant(tenant);
        this.closeList();
    }

    toggleLock() {
      this.menuService.toggleLock();
    }

    closeList() {
        this.filterString = '';
        this.isOpened = false;
    }

    showExpandCollapse(event: any) {
      defer(() => {
        this.showExpandCollapseIcon = true;
        this.showExpandCollapseIconChange.emit(this.showExpandCollapseIcon);
      })
    }

    hideExpandCollapse(event: any) {
        this.showExpandCollapseIcon = false;
        this.showExpandCollapseIconChange.emit(this.showExpandCollapseIcon);
    }

    collapseNav(event: any) {
        if (this.svc.area.length > 0) {
          this.menuService.unsetArea();
        } else {
          this.menuService.resetArea();
        }
    }

    ngOnDestroy(): void {
        this.apiService.currentNetwork.unsubscribe();
        this.subscription.unsubscribe();
    }

    get expandCollapseIconVisible() {
      return this.showExpandCollapseIcon || this.svc?.area?.length <= 0;
    }
}
