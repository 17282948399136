<div class="item-selector-container" (click)="toggleList($event)"  (clickOutside)="handleClickOutside($event)" [ngClass]="{'hide-header': !showHeader}">
  <div *ngIf="showHeader" (click)="toggleList($event); stopProp($event)" class="current-item">
    {{ currentItem.name }}
  </div>
  <div [ngClass]="{ opened: isOpened }" (click)="openList()" class="shadow items-container">
    <div class="row">
      <div *ngIf="showHeader" (click)="toggleList($event)" class="current-item">
        {{ currentItem.name }}
      </div>
    </div>
    <div id="ListArea">
      <div class="list-area search row">
        <input
          (click)="stopProp($event)"
          [(ngModel)]="filterString"
          (ngModelChange)="filterStringChange.emit($event)"
          placeholder="Type to Filter"
          type="text"
        />
      </div>
      <div class="headerItemList row">
        <div
          (click)="itemSelected(item)"
          *ngFor="let item of items | tableSearch: filterString | sortby: 'name'"
          class="selectNetwork row"
          [ngClass]="{'item-selected': item.selected}"
        >
          <div *ngIf="item.iconClass" class="{{item.iconClass}}"></div>
          <div *ngIf="!item.iconClass" class="status s{{ item.status || '' }}" id="nStatList{{ item.id }}">
            <div class="anim5"></div>
            <div class="ha-badge" *ngIf="item.haEnabled"></div>
          </div>
          {{ item.name || item.label }}
        </div>
        <div
          (click)="addNetItemSelected($event)"
          *ngIf="showAddNewItem"
          class="item row addItemButton"
        >
          <div class="icon append icon-BigSoftPlus"></div>
          <div class="content">{{addNewItemLabel}}</div>
        </div>
      </div>
      <div *ngIf="showManageItems" class="navItem" id="ManageNetworkButton" routerLink="{{manageItemsLink}}" (click)="manageItemsSelected($event)">{{manageItemsLabel}}</div>
    </div>
  </div>
</div>
