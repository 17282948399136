<app-side-filter
    (filterRemoved)="removeFilter($event)"
    *ngIf="showFilter"
    [appliedFilters]="appliedFilters"
    [columnFilters]="columnFilters"
    [isTimeSearchAvailable]="isTimeSearchAvailable"
    [isNotDialLogs]="isNotDialLogs"
    [isUTC]="isUTC"
    class="header-filter"
></app-side-filter>
<div class="hidden-items-container">
    <div
        *ngIf="gridRendered && showFilter"
        class="hidden-columns-list"
        [ngClass]="{ 'hidden-results': hiddenResults }"
        id="ColumnVisibilityHeader"
    >
        <div
            (click)="setColumnVisibilityColumn(col, true)"
            *ngFor="let col of hiddenColumns"
            class="hidden-column-item ag-dnd-ghost ag-unselectable ag-theme-alpine"
            id="ColumnVisibility_{{ col.headerName }}"
            title="Show column"
        >
            <span class="ag-dnd-ghost-icon">
                <span class="ag-icon ag-icon-eye-slash"></span>
            </span>
            <div class="ag-dnd-ghost-label">{{ col.headerName }}</div>
        </div>
        <div class="system-policies-toggle" *ngIf="showToggle">
            <span class="toggle-text">Show System Generated Policies</span>
            <div class="toggle" [ngClass]="{ on: showSystem }" (click)="showSystemToggled()">
                <span [hidden]="!showSystem" style="margin-left: -1.125rem">SHOW</span>
                <span [hidden]="showSystem" style="margin-right: -0.9375rem">HIDE</span>
                <div class="switch"></div>
            </div>
        </div>
    </div>
    <div class="hidden-results-container" [ngClass]="{ 'loading-results': loadingHiddenResults }" *ngIf="hiddenResults">
        <span class="toggle-text"
            >Some results have been filtered. <a (click)="enablePowerUser()">Click here</a> to enable power user mode
            and display hidden results.
        </span>
        <span *ngIf="loadingHiddenResults" class="spinner"></span>
    </div>
</div>
<div (resized)="resizeGridColumnsDebounced($event)" class="nf-ag-grid" id="ItemTable">
    <ag-grid-angular
        #nfAgGrid
        (gridReady)="onGridReady($event)"
        [columnDefs]="mergedColumnDefinitions"
        [components]="frameworkComponents"
        [getDataPath]="getDataPath"
        [getRowId]="getRowNodeId"
        [gridOptions]="gridOptions"
        [modules]="gridModules"
        [rowData]="rowData"
        (cellClicked)="onCellClicked($event)"
        class="nf-ag-grid-table ag-theme-alpine"
        id="ItemGrid"
    >
    </ag-grid-angular>
</div>
<div
    [ngClass]="{ open: openMenu }"
    [ngStyle]="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    class="tMenu action-menu"
    id="RowActionMenu"
    #contextMenu
>
    <div
        (click)="openItem(selectedItem)"
        *ngIf="selectedItem.actionList.includes('update')"
        class="tActionRow"
        id="EditSelectionButton"
    >
        Edit
    </div>
    <div
      (click)="goToEdit(selectedItem)"
      *ngIf="selectedItem.actionList.includes('go-to-update')"
      class="tActionRow"
      id="EditSelectionButton"
    >
        Go to Edit
    </div>
    <div
        (click)="editItem(selectedItem)"
        *ngIf="selectedItem.actionList.includes('updateRoles') && editItem"
        class="tActionRow"
        id="RowEditRoles_{{ index }}"
    >
        Edit Roles
    </div>
    <div
        (click)="editItemDetail(selectedItem)"
        *ngIf="selectedItem.actionList.includes('editDetails') && editItemDetail"
        class="tActionRow"
        id="RowEditRoles_{{ index }}"
    >
        Edit Details
    </div>
    <div
        (click)="resetItem(selectedItem)"
        *ngIf="selectedItem.actionList.includes('rotatePassword')"
        class="tActionRow"
        id="RowReset_{{ index }}"
    >
        New Secret
    </div>
    <div
        (click)="deactivateItem(selectedItem)"
        *ngIf="
            selectedItem.actionList.includes('activateOrDeactivate') || selectedItem.actionList.includes('deactivate')
        "
        [hidden]="selectedItem.active === 0"
        class="tActionRow"
        id="RowDelete_{{ index }}"
    >
        Deactivate
    </div>
    <div
        (click)="activateItem(selectedItem.id)"
        *ngIf="selectedItem.actionList.includes('activateOrDeactivate') || selectedItem.actionList.includes('activate')"
        [hidden]="selectedItem.active === 1"
        class="tActionRow"
        id="RowActivate_{{ index }}"
    >
        Activate
    </div>
    <div
        (click)="resetMFA(selectedItem)"
        *ngIf="selectedItem.actionList.includes('reset') || selectedItem.actionList.includes('resetMfa')"
        class="tActionRow"
        id="ResetSelectionButton"
    >
        Reset MFA
    </div>
    <div
        (click)="releaseIP(selectedItem)"
        *ngIf="selectedItem.actionList.includes('releaseIP')"
        class="tActionRow"
        id="ResetSelectionButton"
    >
        Release IP
    </div>
    <div
        (click)="upgrade(selectedItem)"
        *ngIf="selectedItem.actionList.includes('upgrade')"
        class="tActionRow"
        id="UpgradeSelectionBtn"
    >
        Upgrade
    </div>
    <div
        (click)="move(selectedItem)"
        *ngIf="selectedItem.actionList.includes('move')"
        class="tActionRow"
        id="UpgradeSelectionBtn"
    >
        Move
    </div>
    <div
        (click)="reissueToken(selectedItem)"
        *ngIf="selectedItem.actionList.includes('reissue')"
        class="tActionRow"
        id="ResetSelectionButton"
    >
        Reissue Token
    </div>
    <div
        (click)="reEnroll(selectedItem)"
        *ngIf="selectedItem.actionList.includes('re-enroll')"
        class="tActionRow"
        id="ResetSelectionButton"
    >
        Re-Enroll
    </div>
    <div
        (click)="shareItem(selectedItem)"
        *ngIf="selectedItem.actionList.includes('share')"
        class="tActionRow"
        id="ShareSelectionButton"
    >
        Share
    </div>
    <div
        (click)="deleteItem(selectedItem)"
        *ngIf="selectedItem.actionList.includes('delete')"
        class="tActionRow"
        id="DeleteSelectionButton"
    >
        Delete
    </div>
    <div
        (click)="openTopology(selectedItem)"
        *ngIf="selectedItem.actionList.includes('topology')"
        class="tActionRow"
        id="TopologySelectionButton"
    >
        Visualize
    </div>
    <div
        (click)="openMetrics(selectedItem)"
        *ngIf="selectedItem.actionList.includes('metrics')"
        class="tActionRow"
        id="MetricsSelectionButton"
    >
        Metrics
    </div>
    <div
        (click)="openEvents(selectedItem)"
        *ngIf="selectedItem.actionList.includes('events')"
        class="tActionRow"
        id="EventsSelectionButton"
    >
        Events
    </div>
    <div
        (click)="openJson(selectedItem)"
        *ngIf="selectedItem.actionList.includes('json')"
        class="tActionRow"
        id="JsonSelectionButton"
    >
        JSON
    </div>
    <div
        (click)="openPublicCertModal(selectedItem)"
        *ngIf="selectedItem.actionList.includes('publicCert')"
        class="tActionRow"
        id="PublicCertificateSelectionButton"
    >
        Public Certificate
    </div>
    <div
        (click)="resume(selectedItem)"
        *ngIf="selectedItem.actionList.includes('resume')"
        class="tActionRow"
        id="ResumeSelectionButton"
    >
        Resume
    </div>
    <div
        (click)="resize(selectedItem)"
        *ngIf="selectedItem.actionList.includes('resize')"
        class="tActionRow"
        id="ResizeSelectionButton"
    >
        Resize
    </div>
    <div
        (click)="restartHost(selectedItem)"
        *ngIf="selectedItem.actionList.includes('restartHost')"
        class="tActionRow"
        id="RestartHostSelectionButton"
    >
        Restart Host
    </div>
    <div
    (click)="restart(selectedItem)"
    *ngIf="selectedItem.actionList.includes('restart')"
    class="tActionRow"
    id="RestartSelectionButton"
    >
        Restart
    </div>
    <div
        (click)="restoreBackup(selectedItem)"
        *ngIf="selectedItem.actionList.includes('restoreBackup')"
        class="tActionRow"
        id="RestartHostSelectionButton"
    >
        Restore Backup
    </div>
    <div
    (click)="enableHA(selectedItem)"
    *ngIf="selectedItem.actionList.includes('enableHA')"
    class="tActionRow"
    id="EnableHASelectionButton"
>
    Enable HA
</div>
    <div
        (click)="createBackup(selectedItem)"
        *ngIf="selectedItem.actionList.includes('createBackup')"
        class="tActionRow"
        id="RestartHostSelectionButton"
    >
        Create Backup
    </div>
    <div
        (click)="restartProcess(selectedItem)"
        *ngIf="selectedItem.actionList.includes('restartProcess')"
        class="tActionRow"
        id="RestartProcessSelectionButton"
    >
        Restart Process
    </div>
    <div
        (click)="suspend(selectedItem)"
        *ngIf="selectedItem.actionList.includes('suspend')"
        class="tActionRow"
        id="SuspendSelectionButton"
    >
        Suspend
    </div>
</div>
<div
    [ngClass]="{ open: openHeaderMenu }"
    [ngStyle]="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    class="tMenu action-menu header-menu"
    id="HeaderActionMenu"
>
    <div (click)="resetTableColumns()" class="tActionRow" id="ResetTableButton">Restore Default Table</div>
    <div (click)="downloadAll($event)" *ngIf="showDownload()" class="tActionRow" id="DownloadAllToCSV">
        {{ view === 'users' ? 'Download User Roles' : 'Download All' }}
    </div>
    <div (click)="downloadCsv($event)" *ngIf="anySelected()" class="tActionRow" id="DownloadCSV_Bulk">
        Download Selected
    </div>
    <div
        (click)="upgradeEdgeRouters($event)"
        *ngIf="anySelected() && showBulkUpload()"
        class="tActionRow"
        id="Upgrade_Bulk"
    >
        Upgrade Selected
    </div>
    <div
        (click)="updateAuthPolicy($event)"
        *ngIf="anySelected() && updateAuthPolicy && featureService.powerUserEnabled"
        class="tActionRow"
        id="updateAuthPolicy"
    >
        Update Auth Policy
    </div>
    <div (click)="downloadAllEvents($event)" *ngIf="showDownloadEvents()" class="tActionRow" id="DownloadAllEvents">
        Download All
    </div>
    <div
        (click)="downloadFilteredEvents($event)"
        *ngIf="showDownloadEvents()"
        class="tActionRow"
        id="DownloadCSV_Filtered"
    >
        Download Filtered
    </div>
</div>
<div
    (clickOutside)="closeHeaderFilter($event)"
    [ngClass]="{ open: showFilterOptions }"
    [ngStyle]="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    class="tMenu action-menu header-menu"
    id="HeaderFilterOptions"
>
    <div
        (click)="applyFilter($event, filter)"
        *ngFor="let filter of filterOptions"
        class="tActionRow"
        [ngClass]="{ 'has-icon': filter.icon }"
        [id]="filter.columnId"
    >
        <span *ngIf="filter.bubbleClass" [ngClass]="filter.bubbleClass" class="bubble">
            <svg *ngIf="filter.showLoader" class="circular" viewBox="25 25 50 50">
                <circle
                    class="path"
                    cx="50"
                    cy="50"
                    fill="none"
                    r="20"
                    stroke-miterlimit="10"
                    stroke-width="3"
                ></circle>
            </svg>
        </span>
        <span *ngIf="filter.icon" class="os-icon {{ filter.icon }}"></span>
        {{ filter.label }}
    </div>
</div>
<div
    *ngIf="showDateTimePicker"
    class="date-time-container"
    [ngStyle]="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    (clickOutside)="closeHeaderFilter($event)"
>
    <p-calendar
        [(ngModel)]="dateValue"
        (onSelect)="setDateRangeFilter('custom')"
        dateFormat="mm/dd/yy"
        selectionMode="range"
        placeholder="Select Time Range"
        #calendar
    >
        <ng-template pTemplate="header">
            <div class="filter-button-container">
                <div
                    class="toggleButton"
                    [ngClass]="{ selected: selectedRange === 'hour' }"
                    (click)="setDateRangeFilter('hour')"
                >
                    Last Hour
                </div>
                <div
                    class="toggleButton"
                    (click)="setDateRangeFilter('day')"
                    [ngClass]="{ selected: selectedRange === 'day' }"
                >
                    Last Day
                </div>
                <div
                    class="toggleButton"
                    (click)="setDateRangeFilter('week')"
                    [ngClass]="{ selected: selectedRange === 'week' }"
                >
                    Last Week
                </div>
                <div
                    class="toggleButton"
                    (click)="setDateRangeFilter('month')"
                    [ngClass]="{ selected: selectedRange === 'month' }"
                >
                    Last Month
                </div>
            </div>
        </ng-template>
    </p-calendar>
</div>
